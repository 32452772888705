<template>
  <div class="tabvertical">
    <c-tab
      type="vertical"
      :tabItems.sync="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :height="tabHeight"
          :workPermit.sync="workPermit"
          :supWorks.sync="supWorks"
          :gridLoto.sync="gridLoto"
          :gridHeavyEquip.sync="gridHeavyEquip"
          :saveCall.sync="saveCall"
          :isWriting="isWriting"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'swp-references',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          bizNo: '',
          vendorResponseUserName: '',  // 작업업체 책임자
          residentManagerName: '',  // 상주관리자
          construnctionDetails: '',  // 공사내용
          lotoApplyArea: '',  // LOTO 적용구역
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          sopAssessmentCheckTypeCd1: '',  // 잠재위험요소1
          sopAssessmentCheckTypeCd2: '',  // 잠재위험요소2
          sopAssessmentCheckTypeCd3: '',  // 잠재위험요소3
          sopAssessmentCheckTypeCd4: '',  // 잠재위험요소4
          sopAssessmentCheckTypeCd5: '',  // 잠재위험요소5
          sopAssessmentCheckTypeCd6: '',  // 잠재위험요소6
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
          lotos: [],
          deleteLotos: [],
          heavyEquips: [],
          deleteHeavyEquips: [],
        }
      }
    },
    supWorks: {
      type: Array,
      default: function() {
        return [];
      },
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
    gridLoto: {
      type: Object,
      default: function() {
        return {
          columns: [
            {
              name: 'equipmentNo',
              field: 'equipmentNo',
              label: 'LBL0000631', // 설비관리번호
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'equipmentName',
              field: 'equipmentName',
              label: 'LBL0000632', // 설비명
              align: 'left',
              style: 'width:150px',
              sortable: true,
              type: 'link',
            },
            {
              name: 'equipmentTypeName',
              field: 'equipmentTypeName',
              label: 'LBL0000633', // 설비유형
              align: 'center',
              style: 'width:150px',
              sortable: true,
            },
            {
              required: true,
              name: 'sopLotoTypeCd',
              field: 'sopLotoTypeCd',
              label: 'LBL0000635', // 잠금장치
              align: 'center',
              style: 'width:150px',
              sortable: false,
              type: 'select',
              codeGroupCd: 'SOP_LOTO_TYPE_CD'
            },
            {
              name: 'equipmentLocation',
              field: 'equipmentLocation',
              label: 'LBL0000634', // 설비위치
              align: 'center',
              style: 'width:150px',
              sortable: true,
            },
          ],
          height: '350px'
        };
      },
    },
    gridHeavyEquip: {
      type: Object,
      default: function() {
        return {
          columns: [
            {
              required: true,
              name: 'heavyEquipmentName',
              field: 'heavyEquipmentName',
              label: '중장비명', // 중장비명
              align: 'left',
              style: 'width:200px',
              sortable: true,
              type: 'text',
            },
            {
              name: 'amount',
              field: 'amount',
              label: '수량', // 수량
              align: 'right',
              style: 'width:80px',
              sortable: true,
              type: 'number',
            },
            {
              name: 'remarks',
              field: 'remarks',
              label: '비고', // 비고
              align: 'left',
              style: 'width:350px',
              sortable: true,
              type: 'textarea',
            },
          ],
          height: '350px'
        };
      },
    },
  },
  data() {
    return {
      tabHeight: '500px',
      splitter: 5,
      tab: 'resources',
      tabItems: [
        // 작업위치<br />(지도)
        { name: 'resources', icon: 'map', label: 'LBL0000255', component: () => import(`${'./swpResources.vue'}`) },
        // 작업자
        { name: 'contractor', icon: 'engineering', label: 'LBL0000256', component: () => import(`${'./swpContractor.vue'}`) },
        // 작업<br />위험성평가
        { name: 'asssessment', icon: 'verified', label: 'LBL0000257', component: () => import(`${'./swpAssessment.vue'}`) },
        // LOTO
        { name: 'loto', icon: 'lock', label: 'LOTO', component: () => import(`${'./swpLoto.vue'}`) },
        // 중장비
        { name: 'heavyEquip', icon: 'agriculture', label: '중장비', component: () => import(`${'./swpHeavyEquip.vue'}`) },
        // 첨부파일
        { name: 'attach', icon: 'attach_file', label: 'LBL0000258', component: () => import(`${'./swpAttach.vue'}`) },
      ],
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    height() {
      this.setTabHeight();
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setTabHeight();
    },
    setTabHeight() {
      let returnText = '500px';
      returnText = this.height && !isNaN(Number(this.height.replace('px', ''))) ? String(Number(this.height.replace('px', '')) - 30) + 'px' : returnText;
      this.tabHeight = returnText;
    },
  }
};
</script>
